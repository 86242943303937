.etqd {
  height: 216.53333vw;
  padding: 40.53333vw 0 9.6vw;
  text-align: center;
  background: url(/download/etqd/bg.png) no-repeat 0 0/cover;
  font-family: "PingFang SC"; }
  .etqd .sample {
    width: 88.8vw;
    height: 85.06667vw;
    margin: 0 auto 4vw; }
  .etqd .logo {
    width: 46.13333vw;
    height: 11.73333vw;
    margin: 0 auto 22.93333vw; }
  .etqd .download_wrapper {
    width: 91.46667vw;
    height: 11.73333vw;
    margin: 0 auto 25.6vw; }
  .etqd .download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10.66667vw;
    background: #3a55d6;
    line-height: 11.73333vw;
    font-size: 3.73333vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin: auto; }
  .etqd .ios_icon {
    background: url("/download/ios.svg") center no-repeat;
    width: 5.33333vw;
    height: 5.33333vw;
    background-size: 100% 100%;
    margin-right: 1.06667vw; }
  .etqd .tips {
    font-size: 3.2vw;
    color: #261f1f;
    text-align: center;
    font-weight: 500;
    line-height: 1; }
